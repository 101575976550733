import {
  Box,
  VStack,
  Flex,
  chakra,
  Grid,
  GridItem,
  Container,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import React from "react";
import StatsCard from "../Card/StatsCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Image from "next/image";

export default function Section({ data, techs }) {
  const { Head, Icons, Name } = data;

  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
      <Box
        as={Container}
        maxW="7xl"
        p={0}
      >
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={4}
        >
          <GridItem colSpan={2}>
            <VStack alignItems="flex-start" spacing="20px">
              <Text
                fontWeight={"medium"}
                letterSpacing={4}
                lineHeight={1.2}
                textAlign={"center"}
                w={"full"}
                color={"blue.10"}
                fontSize={10}
              >
                {Head?.Subtitle}
              </Text>
              <chakra.h2
                fontSize={{ base: "2xl", md: "3xl" }}
                fontWeight={"medium"}
                textAlign={"center"}
                w={"full"}
                color={"blue.10"}
                lineHeight={1.2}
              >
                {Head?.Title}
              </chakra.h2>
            </VStack>
          </GridItem>
          <GridItem colSpan={2}>
            <Flex textAlign={"center"}>
              <chakra.p
                fontSize="lg"
                color={"gray.10"}
                w={"full"}
                maxW={{ base: "full", sm: "full", md: "900px" }}
                m={"auto"}
              >
                {Head?.Description}
              </chakra.p>
            </Flex>
          </GridItem>
        </Grid>
  
        {Name == "Company" && (
          <Flex mt={{ base: 10, md: 12 }} justifyContent={"center"}  gap={8} flexWrap={"wrap"}>
            {Icons.map((item, index) => {
              return (
                <StatsCard
                  title={item.Name}
                  stat={item.count}
                  key={index}
                  icon={
                    <Image
                      className="mb-0"
                      src={
                        process.env.NEXT_PUBLIC_SERVER_URL +
                        item.Icon.data.attributes.url
                      }
                      alt={item.Icon.data.attributes.name}
                      height={64}
                      width={64}
                    />
                  }
                />
              );
            })}
          </Flex>
        )}
  
        {Name == "Expertise" && (
          <Grid
            mt={{ base: 10, md: 12 }}
            templateColumns={{
              base: "repeat(2, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
          >
            {Icons.map((item, index) => {
              return (
                <Flex key={index} align={"center"} textAlign={"center"} mb={10}>
                  <Box mr={{ base: "8px", md: "30px" }}>
                    <Image
                      boxSize={"40px"}
                      className="mb-0"
                      src={
                        process.env.NEXT_PUBLIC_SERVER_URL +
                        item.Icon.data.attributes.url
                      }
                      alt={item.Icon.data.attributes.name}
                      height={40}
                      width={40}
                    />
                  </Box>
                  <Box>
                    <Text>{item.Name}</Text>
                  </Box>
                </Flex>
              );
            })}
          </Grid>
        )}
  
        {Name == "Technologies" && (
          <Tabs mt={{ base: 8, md: 12 }} variant="unstyled" overflowY="auto">
            <TabList justifyContent={"center"} border="0px" flexWrap={"wrap"}>
              {techs.map((tech, index) => {
                return (
                  <Tab
                    color={"gray.10"}
                    fontSize={{ base: "md", sm: "xl" }}
                    _selected={{
                      color: "orange.10",
                      borderColor: "orange.10",
                      borderBottom: "2px",
                    }}
                    key={index}
                  >
                    {tech?.attributes.Name}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels pt={{ base: 0, md: 6 }}>
              {techs.map((tech, index) => {
                return (
                  <TabPanel key={index} p={0}>
                    <Box className="technologies_tabs_wrapper">
                      {tech?.attributes.technologies.data?.map((ele, i) => {
                        const imageName =
                          ele?.attributes?.Icon?.data?.attributes.name;
  
                        return (
                          <Box key={i} textAlign={"center"} mb={4}>
                            <Image
                              className="mb-0 tab_image_wrap"
                              src={
                                process.env.NEXT_PUBLIC_SERVER_URL +
                                ele?.attributes?.Icon?.data?.attributes.url
                              }
                              alt={ele?.attributes?.Icon?.data?.attributes.name}
                              height={100}
                              width={160}
                              style={{
                                objectFit: "contain",
                                padding: "0 8px",
                                width:
                                  imageName === "meanjs-removebg-preview.png" ||
                                  imageName === "mern-logo-removebg-preview.png"
                                    ? "350px"
                                    : "160px",
                              }}
                              unoptimized
                            />
                            <Text color={"blue.10"} fontWeight={"medium"}>
                              {ele.attributes.name}
                            </Text>
                          </Box>
                        );
                      })}
                    </Box>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        )}
  
        {Name == "Brands" && (
          <Box mt={{ base: 2, md: 8 }}>
            <Slider {...settings} className="brandsslider-wrapper">
              {Icons.map((item, index) => {
                const imageName = item.Icon.data.attributes.name;
                return (
                  <Flex key={index}>
                    <Box>
                      <Image
                        className="mb-0"
                        src={
                          process.env.NEXT_PUBLIC_SERVER_URL +
                          item.Icon.data.attributes.url
                        }
                        alt={item.Icon.data.attributes.name}
                        height={100}
                        width={
                          imageName ===
                          "AirAsia-Logo-2009-removebg-preview-1.webp"
                            ? 153
                            : 220
                        }
                        style={{ margin: "0 auto" }}
                      />
                    </Box>
                  </Flex>
                );
              })}
            </Slider>
          </Box>
        )}
  
        {Name == "Awards & Recognition" && (
          <Flex
            mt={12}
            justify={"center"}
            flexWrap={"wrap"}
          >
            {Icons.map((item, index) => {
              const iconName = item.Icon.data.attributes.name;
              return (
                <Flex
                  key={index}
                  align={"center"}
                  textAlign={"center"}
                  justifyContent={"center"}
                  _notLast={{
                    mb:{base:10,md:0}
                  }}
                >
                  <Flex
                    width={"230px"}
                    justify={{ base: "center", md: "center", lg: "flex-start" }}
                  >
                    <Image
                      className="mb-0"
                      height={120}
                      width={120}
                      style={{
                        objectFit: "contain",
                        width: iconName === "upwork.png" ? "230px" : "120px",
                      }}
                      src={
                        process.env.NEXT_PUBLIC_SERVER_URL +
                        item.Icon.data.attributes.url
                      }
                      alt={item.Icon.data.attributes.name}
                      unoptimized={iconName === "upwork.png"}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
      </Box>
    );
}
