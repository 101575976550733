import React from "react";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function StatsCard({ title, stat, icon }) {
  const sign =
    title === "Client Satisfaction"
      ? "%"
      : title === "Sparkle In Market"
      ? ""
      : "+";

  return (
    <Card
      className="starCard"
      align="center"
      bgColor={"#10326a"}
      textColor="white"
      cursor={"pointer"}
      maxW={"190px"}
      w={"full"}
    >
      <CardHeader pb={"10px"}>
        {/* <Heading size='md'> {icon}</Heading> */}
        {icon}
      </CardHeader>
      <CardBody textAlign={"center"} pt={3}>
        <Box>
          <Text fontSize={"3xl"} fontWeight={"normal"}>
            {stat}
            <span style={{ opacity: ".5" }}>{sign}</span>
          </Text>
          <Text fontWeight={"normal"} noOfLines={2}>
            {title}
          </Text>
        </Box>
      </CardBody>
    </Card>
  );
}
